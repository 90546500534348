import contact from './lib/contact'
import rebox from './lib/rebox'
import { toggleBelowFold, scrollSpy, smoothScroll } from './lib/scroll'

$(document).ready(function () {
  contact('#contact-form')
  rebox('.instanode-headstart-thumbimage')
  toggleBelowFold()
  // scrollSpy('.header-nav-sub:visible', {offset: 0})
  // smoothScroll('a[href^="#"]', {offset: 0})

  $(document).scroll(function () {
    var scroll = $(this).scrollTop()
    if ($(window).width() >= 991 && scroll > 0) {
      $('.header').addClass('fixed')
      $('.second-nav--wrapper').fadeOut(300)
    } else {
      $('.header').removeClass('fixed')
      $('.second-nav--wrapper').fadeIn(200)
    };
  })

  if ($(window).width() <= 990) {
    $('.second-nav--wrapper').remove()
  }

  const secondNavWrapper = document.querySelector('.header-nav-content li.active .second-nav--wrapper')

  if (secondNavWrapper) {
    const wrapperHeight = secondNavWrapper.offsetHeight

    $('.content').css('padding-top', (wrapperHeight))
    $('.carousel-item .jonnitto-prettyembed-wrapper').css('padding-top', (wrapperHeight - 9))
  }
})

$(document).ready(function () {
  $('.read-more').on('click', function () {
    $(this).prev().toggle()
    if ($(this).text() === $(this).attr('label-open')) {
      $(this).text($(this).attr('label-closed'))
    } else {
      $(this).text($(this).attr('label-open'))
    }
  })
})

$(document).ready(function () {
  // Add class 'active' to section when near top of viewport
  const allSections = document.querySelectorAll('.grid-content')

  window.onscroll = function () {
    allSections.forEach(section => {
      if (section.getBoundingClientRect().top <= 200) {
        section.classList.add('active')
      }
    })
  }
})

$('.language-selected p').click(function () {
  $('.languages-list').slideToggle()
  $('.languages').toggleClass('active')
})
